import React, { useState, Suspense, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dimmer, Loader, Message, Transition } from "semantic-ui-react";
import MicrosoftLogin from "react-microsoft-login";
import axios from "axios";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { checkSsoStatus, login, ssoUserLoggedIn } from "../../Redux/Actions/authAction";
import "./../../config";
import SSOLogin from "./SSOLogin";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const testClientId = "e284194e-3218-4557-be77-6643231d35b1";
  const defaultType = "Microsoft";
  const [ssoClientId, setSsoClientId] = useState(testClientId);
  const [ssoTenantUrl, setSsoTenantUrl] = useState("");
  const [ssoType, setSsoType] = useState(defaultType);
  const [msalInstance, onMsalInstanceChange] = useState();
  const [showDefaultLogin, setShowDefaultLogin] = useState(false);
  const [showMicrosoftLogin, setShowMicrosoftLogin] = useState(false);
  const [showSsoLogin, setShowSsoLogin] = useState(false);

  // TODO: get this from the db
  const [companyId, setCompanyId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const { isLoggedIn, user: currentUser } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.msg);
  const baseApiUrl = global.config.baseApiUrl;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //get the config companyId to retrieve companyInfo from DB
  useEffect(() => {
    setCompanyId(global.config.companyId);

    // TODO get companyInfo from DB
    dispatch(checkSsoStatus(global.config.companyId)).then((ssoInfo) => {
      //console.log("ssoInfo : " + JSON.stringify(ssoInfo));

      if (!ssoInfo) {
        //ssoInfo is not present; use default login
        setSsoClientId(null);
        setSsoTenantUrl("");
        setSsoType("default");
        setShowDefaultLogin(true);
      } else {
        //if defaultLoginEnabled, show regular login form in addition to sso
        if (ssoInfo.defaultLoginEnabled === "Y") {
          setShowDefaultLogin(true);
        } else {
          setShowDefaultLogin(false);
        }
        //check through available configurations
        //for now we only support ONE entry
        if (ssoInfo.ssoConfig && ssoInfo.ssoConfig[0]) {
          //console.log("ssoConfig result ONE: " + JSON.stringify(ssoInfo.ssoConfig[0]));
          let ssoConfig = {};
          ssoConfig = Object.assign(ssoConfig, ssoInfo.ssoConfig[0]);
          //allow for multiple types
          switch (ssoConfig.ssoType) {
            case "Microsoft":
              setShowMicrosoftLogin(true);
              break;
            case "SAML":
              setShowSsoLogin(true);
              break;
            default:
              break;
          }
          setSsoClientId(ssoConfig.tenantId);
          setSsoTenantUrl(ssoConfig.tenantUrl);
          setSsoType(ssoConfig.ssoType);
        }
        //TODO: Add support for displaying multiple sso options if configured
      }
    });
    // TODO Set SSO info
  }, [global.config.companyId]);

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const toInputLowercase = (e) => {
    e.target.value = ("" + e.target.value).toLowerCase();
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
    setIsVisible(false);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setIsVisible(false);
  };

  const handleDefaultLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    setCompanyId(global.config.companyId);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(companyId, username, password, null)).then(
        (signingIn) => {
          console.log("signingIn: " + JSON.stringify(signingIn));
          console.log("username: " + JSON.stringify(username));
          console.log("AUTH isLoggedIn: " + JSON.stringify(isLoggedIn));
          console.log("AUTH currentUser: " + JSON.stringify(currentUser));

          if (
            signingIn != null &&
            Object.keys(signingIn).length != 0 &&
            JSON.stringify(signingIn).length > 0 &&
            (signingIn.username.toLowerCase() === username.toLowerCase() ||
              String(signingIn.id).toLowerCase() === username.toLowerCase() || signingIn.email.toLowerCase() === username.toLowerCase())
          ) {
            //props.history.push("/profile");
            //window.location.reload();
            navigate("/profile");
          } else {
            console.log("INVALID LOGIN!!!!");
            setIsVisible(true);
            setLoading(false);
          }
        },
        (error) => {
          setIsVisible(true);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  };

  /*
  SINGLE-SIGN-ON HANDLER(S)
  */
  const handleSsoLogin = (err, authData) =>{
    console.log("Auth data is ", authData);
    console.log("err data is: ", err);
    dispatch(ssoUserLoggedIn(authData))
    .then(signingIn=>{
      console.log("signingIn: " + JSON.stringify(signingIn));
      //console.log("useremail: " + JSON.stringify(useremail));
      console.log("AUTH isLoggedIn: " + JSON.stringify(isLoggedIn));
      console.log("AUTH currentUser: " + JSON.stringify(currentUser));
      navigate("/profile");
    })
    .catch(err=>{
      setIsVisible(true);
      setLoading(false);
    })
  }
  //Microsoft SSO
  const handleMicrosoftLogin = (err, authData, msal) => {
    console.log(err, authData);
    //check for valid username
    if (authData && authData.account.userName) {
      
      if (!err && authData) {
        //onMsalInstanceChange(msal);
      }
      
      // call api to find that user in RR
      let useremail = authData.account.userName;
      let url = baseApiUrl + "/login";
      console.log("find user: url: " + url);
      //let testUserId = 1747053227;
      let reqBody = {
        userid: useremail,
        companyId: "",
        username: "",
        password: "",
      };
      console.log("The userid is: " + JSON.stringify(useremail));
      dispatch(login("", useremail, "", msal)).then(
        (signingIn) => {
          console.log("signingIn: " + JSON.stringify(signingIn));
          console.log("useremail: " + JSON.stringify(useremail));
          console.log("AUTH isLoggedIn: " + JSON.stringify(isLoggedIn));
          console.log("AUTH currentUser: " + JSON.stringify(currentUser));
          //NOTE: we can't validate returned user by email because email is not
          //part of the userInfo returned from login.
          navigate("/profile");
        },
        (error) => {
          setIsVisible(true);
          setLoading(false);
        }
      );
    }
  };

  return (
    <Suspense
      fallback={
        <Dimmer active inverted>
          <Loader size="large">{"Loading..."}</Loader>
        </Dimmer>
      }
    >
      <div className="col-md-12">
        <div className="card card-container">
          <Form onSubmit={handleDefaultLogin} ref={form}>
            <div className="form-group">
              <label htmlFor="instructorsignin">
                <h3>Instructor Log in</h3>
              </label>
            </div>

            <Transition visible={isVisible} animation="scale" duration={500}>
              <Message negative>
                <Message.Header>Invalid Username/Userid</Message.Header>
                <p>Please enter valid credentials.</p>
              </Message>
            </Transition>

            {showDefaultLogin && (
              <div>
                <div className="form-group">
                  <label htmlFor="username">Username/Userid</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="username"
                    value={username}
                    onChange={onChangeUsername}
                    onInput={toInputLowercase}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                  />
                </div>

                <div className="form-group">
                  <button
                    className="btn btn-primary btn-block"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Log in</span>
                  </button>
                </div>

              </div>
            )}

            {showSsoLogin && (
              <div style={{ textAlign: "center"}}>
                <SSOLogin authCallback={handleSsoLogin}/>
              </div>
            )}

            {showMicrosoftLogin && (
              <div style={{ textAlign: "center" }}>
                <MicrosoftLogin
                  clientId={ssoClientId}
                  tenantUrl={ssoTenantUrl}
                  authCallback={handleMicrosoftLogin}
                />
              </div>
            )}

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </Suspense>
  );
};

export default Login;
