import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Button, Form, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { getLMSEnrollments, selectUser, selectUsers } from "../../Redux/Actions/enrollmentsAction";
import { courseSelect, sessionSelect, eventSelect } from "../../Redux/Actions/coursesAction";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import OraclelearnRosterTable from "../components/oraclelearnRosterTable";
import UserInfoModalFord from "../components/usersModalFord";
import UserInfoModal from "../components/usersModal";
import _ from "lodash";
import '../../config';
import LoadSpinner from '../../components/LoadSpinner';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./../../components/Loader";

const ManageOfferingActivityRoster = () => {
  const [course, setCourse] = useState({});
  const [offering, setOffering] = useState({});
  const [activity, setActivity] = useState({});
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [usersInfo, setUsersInfo] = useState([]);

  const [enrollmentsLoading, setEnrollmentsLoading] = useState(true);
  const [usersLoading, setUsersLoading] = useState(false);
  
  const [courseId, setCourseId] = useState("");
  const [offeringId, setOfferingId] = useState("");
  const [offeringStartDate, setOfferingStartDate] = useState("");
  const [offeringEndDate, setOfferingEndDate] = useState("");
  const [activityId, setActivityId] = useState("");

  const [courseNumber, setCourseNumber] = useState("");
  const [offeringNumber, setOfferingNumber] = useState("");
  const [activityNumber, setActivityNumber] = useState("");

  const [courseName, setCourseName] = useState("");
  const [offeringName, setOfferingName] = useState("");
  const [activityName, setActivityName] = useState("");

  const navigate= useNavigate();
  const inputElement = useRef();

  //let baseApiUrl = "http://rldoraclelearn.localhost:8080/v1";
  let baseApiUrl = global.config.baseApiUrl;

  let companyId = global.config.companyId;
  
  const { course: currentCourse, session: currentSession, event: currentEvent } = useSelector(state => state.courses);
  const { user: currentUser } = useSelector(state => state.auth);
  const { user: selectedUser, users: selectedUsers, enrollments: currentEnrollments } = useSelector(state => state.enrollments);
  
  const dispatch = useDispatch(); 

  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [enrollments, setEnrollments] = useState([]);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
    console.log('username: ' + JSON.stringify(username));
  };

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
    console.log('name: ' + JSON.stringify(name));
  };

  const handleClose = () => {
    console.log("Clicked handleClose()********");
    setDisabled(true);
    dispatch(selectUser({}));
    setName("");
    setUsername("");
    setUserInfo({});
    setShow(false);
    inputElement.current.focus();
  };

  //const handleClose = () => setShow(false);
  
  const [isSending, setIsSending] = useState(false);
  const isMounted = useRef(true);

  const handleBack = (e) => {
    console.log("Inside handleBack");
    e.preventDefault();    
    navigate("/selectRoster", { replace: true });
  }

  // set isMounted to false when we unmount the component
  //useEffect(() => {
  //  return () => {
  //    isMounted.current = false
  //  }
  //}, [])

  const handleKeypress = (e) => {
    console.log("handleKeypress", e);
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      console.log("enter");
      handleSearchUsers();
    }
  };

  const handleSearchUsers = () => {
    // send the actual request
    let url = baseApiUrl + "/people";
    console.log('getOraclelearnUserInfo: url: ' + url);

    setUsersLoading(true);
    if (name == null || name === '') {
      setUsersInfo([]);
      setUsersLoading(false);
      setShow(true);
      setDisabled(true);
    } else {
      setUsersInfo([]);
      let reqBody = {"search_text": name};
      console.log('The search_text is: ' + JSON.stringify(name));

      axios.post(url, reqBody).then((response) => {
        console.log("People Search Response:" + JSON.stringify(response.data.users));
        //dispatch(selectUser(response.data.users));
        console.log("users info is: " + JSON.stringify(response.data.users));
        console.log("selectedUser is " + JSON.stringify(selectedUser));
        dispatch(selectUsers(response.data.users));
        if (response.data.users != null && response.data.users.length > 0 && selectedUser && Object.keys(selectedUser).length != 0) {
          setDisabled(false);
        }
         else {
          setDisabled(true);
         }

        setUsersInfo(response.data.users);

        setUsersLoading(false);
        setShow(true);
      }, (error) => {
        dispatch(selectUsers([]));
          setDisabled(true);
          setUsersInfo([]);
          setUsersLoading(false);
          console.log(error);
      });

      setShow(true);
    }
} // update the callback if the state changes

  const onUserInfoFormSubmit = (e) => {
    e.preventDefault();
    handleClose();
    handleSubmit();
  };

  function handleSubmit(e) {
    setEnrollmentsLoading(true);
    console.log("Inside handleSubmit: " + JSON.stringify(selectedUser));
    var failed = false;
    //e.preventDefault();
    // Set the UserId in the Redux
    // Check-In Learner | Mark Attendance | Completion | Walk-In Learner
    // Check Learner if in DB, also check the enrollment listings
    // Walk-In, 
    // Check-In
    // Mark Attendance
    // Mark Completion

    // send the actual request
    let url = baseApiUrl + "/learner/check";
    console.log('getOraclelearnLearnerCheck: url: ' + url);

    if (!username) {
      setUsername(selectedUser);
    }

    let course_number = '';
    let offering_number = '';
    let activity_number = '';
    let course_id = '';
    let offering_id = '';
    let activity_id = '';
    let course_name = '';
    let offering_name = '';
    let activity_name = '';
    let offering_start_date = '';
    let offering_end_date = '';

    if (currentCourse) {
      course_number = currentCourse.course_number;
      course_name = currentCourse.course_name;
      course_id = currentCourse.course_id;
    } else {
      course_number = sessionStorage.getItem('course_number');
      course_name = sessionStorage.getItem('course_name');
      course_id = sessionStorage.getItem('course_id');
    }

    if (currentSession) {
      offering_number = currentSession.offering_number;
      offering_name = currentSession.offering_name;
      offering_id = currentSession.offering_id;
      offering_start_date = currentSession.offering_start_date;
      offering_end_date = currentSession.offering_end_date;
    } else {
      offering_number = sessionStorage.getItem('offering_number');
      offering_name = sessionStorage.getItem('offering_name');
      offering_id = sessionStorage.getItem('offering_id');
      offering_start_date = sessionStorage.getItem('offering_start_date');
      offering_end_date = sessionStorage.getItem('offering_end_date');
    }
  
    if (currentEvent) {
      activity_number = currentEvent.activity_number;
      activity_name = currentEvent.activity_name;
      activity_id = currentEvent.activity_id;
    } else {
      activity_number = sessionStorage.getItem('activity_number');
      activity_name = sessionStorage.getItem('activity_name');
      activity_id = sessionStorage.getItem('activity_id');
    }

    setCourseNumber(course_number);
    setOfferingNumber(offering_number);
    setActivityNumber(activity_number);

    setCourseName(course_name);
    setOfferingName(offering_name);
    setActivityName(activity_name);

    setCourseId(course_id);
    setOfferingId(offering_id);
    setActivityId(activity_id);

    setOfferingStartDate(offering_start_date);
    setOfferingEndDate(offering_end_date);

    let reqBody = { "courseId": course_id,
                    "courseTitle": course_name,
                    "courseNumber": course_number,
                    "offeringId": offering_id,
                    "offeringTitle": offering_name,
                    "offeringNumber": offering_number,
                    "activityId": activity_id,
                    "activityTitle": activity_name,
                    "activityNumber": activity_number,
                    "offeringStartDate": offering_start_date,
                    "offeringEndDate": offering_end_date,
                    "instructorNumber": currentUser.id,
                    "instructorName": currentUser.instructorname,
                    "learners": [
                        selectedUser
                    ]};
    
    handleClose();
    axios.post(url, reqBody).then(data => {
      console.log('handleSubmit:getLMSEnrollments:data: ' + JSON.stringify(data));
      setRefresh(true);
      dispatch(getLMSEnrollments(data, failed));

      /*if (data) {
        console.log('onUserInfoFormSubmit: data: ' + JSON.stringify(data.data.enrollments) + ' - ' + JSON.stringify(data.data.enrollments.length));
        if (data.data.enrollments && data.data.enrollments.length > 0) {
          console.log('onUserInfoFormSubmit: data.enrollments.size(): ' + data.data.enrollments.length + ' selectedUser: ' + selectedUser);
          var userObj = data.data.enrollments.find(x => x.id === selectedUser.split(':')[0]);
          console.log('onUserInfoFormSubmit: userObj: ' + JSON.stringify(userObj));
          if (userObj) {
            if (userObj.completed === "1") {
              toast.warn(userObj.name + " has already been marked Complete.", {
                position: toast.POSITION.BOTTOM_CENTER
              });
            } else {
              toast.info(userObj.name + " has been successfully Enrolled.", {
                position: toast.POSITION.TOP_CENTER
              });
            }
          } else {
            console.log('onUserInfoFormSubmit: DELAY');
          }
        }
        dispatch(getLMSEnrollments(data, failed));
      }*/ 

      setEnrollmentsLoading(false);
    });
    
    setUsersInfo([]);
    inputElement.current.focus();
  }

  useEffect(() => {
    console.log("currentCourse", currentCourse)
    if (currentCourse) {
      if (!currentCourse.course_id) {
        setCourseId(sessionStorage.getItem('course_id'));
      } else {
        setCourseId(currentCourse.course_id);
      }

      if (!currentCourse.course_number) {
        setCourseNumber(sessionStorage.getItem('course_number'));
      } else {
        setCourseNumber(currentCourse.course_number);
      }
      
      if (!currentCourse.course_name) {
        setCourseName(sessionStorage.getItem('course_name'));
      } else {
        setCourseName(currentCourse.course_name);
      }

      sessionStorage.setItem('course_number', currentCourse.course_number);
      sessionStorage.setItem('course_name', currentCourse.course_name);
      sessionStorage.setItem('course_id', currentCourse.course_id);
    }
  }, [currentCourse]);

  useEffect(() => {
    console.log("currentSession", currentSession)
    if (currentSession) {
      if (!currentSession.offering_id) {
        setOfferingId(sessionStorage.getItem('offering_id'));
      } else {
        setOfferingId(currentSession.offering_id);
      }

      if (!currentSession.offering_name) {
        setOfferingName(sessionStorage.getItem('offering_name'));
      } else {
        setOfferingName(currentSession.offering_name);
      }

      if (!currentSession.offering_number) {
        setOfferingNumber(sessionStorage.getItem('offering_number'));
      } else {
        setOfferingNumber(currentSession.offering_number);
      }

      if (!currentSession.offering_start_date) {
        setOfferingStartDate(sessionStorage.getItem('offering_start_date'));
      } else {
        setOfferingStartDate(currentSession.offering_start_date);
      }

      if (!currentSession.offering_end_date) {
        setOfferingEndDate(sessionStorage.getItem('offering_end_date'));
      } else {
        setOfferingEndDate(currentSession.offering_end_date);
      }

      sessionStorage.setItem('offering_number', currentSession.offering_number);
      sessionStorage.setItem('offering_name', currentSession.offering_name);
      sessionStorage.setItem('offering_id', currentSession.offering_id);
      sessionStorage.setItem('offering_start_date', currentSession.offering_start_date);
      sessionStorage.setItem('offering_end_date', currentSession.offering_end_date);
    }
  }, [currentSession]);

  useEffect(() => {
    console.log("currentEvent", currentEvent)
    if (currentEvent) {
      if (!currentEvent.activity_id) {
        setActivityId(sessionStorage.getItem('activity_id'));
      } else {
        setActivityId(currentEvent.activity_id);
      }

      if (!currentEvent.activity_number) {
        setActivityNumber(sessionStorage.getItem('activity_number'));
      } else {
        setActivityNumber(currentEvent.activity_number);
      }

      if (!currentEvent.activity_name) {
        setActivityName(sessionStorage.getItem('activity_name'));
      } else {
        setActivityName(currentEvent.activity_name);
      }

      sessionStorage.setItem('activity_number', currentEvent.activity_number);
      sessionStorage.setItem('activity_name', currentEvent.activity_name);
      sessionStorage.setItem('activity_id', currentEvent.activity_id);
    }
  }, [currentEvent]);

  useEffect(() => {
    if (selectedUser) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedUser]);

  // Get Enrollment Listings
  useEffect(() => {
    let unmounted = false;

    async function getOraclelearnEnrollments() {
      var failed = false;
      setEnrollmentsLoading(true);

      let url = baseApiUrl + "/enrollments";
      console.log('getOraclelearnEnrollments: url: ' + url);

      let course_number = '';
      let offering_number = '';
      let activity_number = '';

      let course_name = '';
      let offering_name = '';
      let activity_name = '';

      if (currentCourse) {
        course_number = currentCourse.course_number;
        course_name = currentCourse.course_name;
      } else {
        course_number = sessionStorage.getItem('course_number');
        course_name = sessionStorage.getItem('course_name');
      }

      if (currentSession) {
        offering_number = currentSession.offering_number;
        offering_name = currentSession.offering_name;
      } else {
        offering_number = sessionStorage.getItem('offering_number');
        offering_name = sessionStorage.getItem('offering_name');
      }

      if (currentEvent) {
        activity_number = currentEvent.activity_number;
        activity_name = currentEvent.activity_name;
      }  else {
        activity_number = sessionStorage.getItem('activity_number');
        activity_name = sessionStorage.getItem('activity_name');
      }

      setCourseNumber(course_number);
      setOfferingNumber(offering_number);
      setActivityNumber(activity_number);

      setCourseName(course_name);
      setOfferingName(offering_name);
      setActivityName(activity_name);

      let reqBody = {"courseNumber": course_number,
                     "offeringNumber": offering_number,
                     "activityNumber": activity_number,
                     "offeringStartDate": offeringStartDate,
                     "offeringEndDate": offeringEndDate,
                     "instructorNumber": currentUser.id,
                     "instructorName": currentUser.instructorname
                   };

      console.log('The Enrollment Listings reqBody: ' + JSON.stringify(reqBody));
      let data = await axios.post(url, reqBody);
      console.log('The Enrollments are: ' + JSON.stringify(data));
      if (data) {
        dispatch(getLMSEnrollments(data, failed));
        setEnrollments(data.enrollments);
      }

      //axios.post(url, reqBody).then((response) => {
      //  console.log('The Enrollment Listings are: ' + JSON.stringify(response));
      //  dispatch(getLMSEnrollments(response, failed));
      //}, (error) => {
      //  console.log("The Get Enrollment Listings Error is: " + error);
      //});
        
      if (!unmounted) {
        //setEnrollments([...enrollments, data.data.enrollments]);
        //setEnrollments(data.data.enrollments);
        setEnrollmentsLoading(false);
    }
  }

  getOraclelearnEnrollments();
  
  return () => {
    unmounted = true;
  };
}, [refresh]);

const [refreshInterval, setRefreshInterval] = useState(20000);

async function refreshEnrollments() {
  var failed = false;
  // retrieve and then setData()
  let url = baseApiUrl + "/enrollments";
      console.log('refreshEnrollments: url: ' + url);

      let course_number = '';
      let offering_number = '';
      let activity_number = '';

      let course_name = '';
      let offering_name = '';
      let activity_name = '';

      if (currentCourse) {
        course_number = currentCourse.course_number;
        course_name = currentCourse.course_name;
      } else {
        course_number = sessionStorage.getItem('course_number');
        course_name = sessionStorage.getItem('course_name');
      }

      if (currentSession) {
        offering_number = currentSession.offering_number;
        offering_name = currentSession.offering_name;
      } else {
        offering_number = sessionStorage.getItem('offering_number');
        offering_name = sessionStorage.getItem('offering_name');
      }

      if (currentEvent) {
        activity_number = currentEvent.activity_number;
        activity_name = currentEvent.activity_name;
      }  else {
        activity_number = sessionStorage.getItem('activity_number');
        activity_name = sessionStorage.getItem('activity_name');
      }

      setCourseNumber(course_number);
      setOfferingNumber(offering_number);
      setActivityNumber(activity_number);

      setCourseName(course_name);
      setOfferingName(offering_name);
      setActivityName(activity_name);

      let reqBody = {"courseNumber": course_number,
                     "offeringNumber": offering_number,
                     "activityNumber": activity_number,
                     "offeringStartDate": offeringStartDate,
                     "offeringEndDate": offeringEndDate,
                     "instructorNumber": currentUser.id,
                     "instructorName": currentUser.instructorname
                    };

      console.log('The refreshEnrollments Listings reqBody: ' + JSON.stringify(reqBody));
      let data = await axios.post(url, reqBody);
      console.log('The refreshEnrollments are: ' + JSON.stringify(data));
      setEnrollments(data.enrollments);
      if (data) {
        dispatch(getLMSEnrollments(data, failed));
      }
}

useEffect(() => {
  if (refreshInterval && refreshInterval > 0) {
    const interval = setInterval(refreshEnrollments, refreshInterval);
    return () => clearInterval(interval);
  }
}, [refreshInterval]);

  return (
    <div className="container">
      <h1>Manage Roster</h1>
      <hr />
      <div>
          <Button variant="secondary" onClick={handleBack}>
            Go Back
          </Button>
      </div>
      <hr />
      <div>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Course</b></InputGroup.Text>
          <InputGroup.Text>{courseNumber} - {courseName}</InputGroup.Text>
        </InputGroup.Prepend>
        <InputGroup.Prepend>
          <InputGroup.Text><b>Offering</b></InputGroup.Text>
          <InputGroup.Text>{offeringNumber} - {offeringName}</InputGroup.Text>
        </InputGroup.Prepend>
        <InputGroup.Prepend>
          <InputGroup.Text><b>Activity</b></InputGroup.Text>
          <InputGroup.Text>{activityNumber} - {activityName}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      </div>
      <hr />
      <div>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Badge ID or Employee ID or Username or First and/or last name</b></InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl placeholder="Badge ID or Employee ID or Username or First and/or last name" onChange={onChangeName}
          ref={inputElement}
          value={name}
          autoFocus
          onFocus={e => e.currentTarget.select()} 
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleSearchUsers();
            }
          }}
        />
        <InputGroup.Append>
          <Button variant="primary" onClick={handleSearchUsers}>Find Learner</Button>
        </InputGroup.Append>
      </InputGroup>
      </div>
      
      {usersLoading ? (
                <div
                    style={{
                        width: "40px",
                        margin: "auto",
                    }}
                >
                    <Loader />
                </div>
            ) : (
                <div>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                      <Modal.Title>Confirm User Selection</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      { usersInfo === [] ? 
                        <UserInfoModal onSubmit={onUserInfoFormSubmit} userInfo={userInfo} /> : (companyId.includes('ford')) ? <UserInfoModalFord onSubmit={onUserInfoFormSubmit} userInfo={usersInfo} /> :
                        <UserInfoModal onSubmit={onUserInfoFormSubmit} userInfo={usersInfo} />
                      }
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                        Close
                        </Button>
                        <Button disabled={disabled} variant="primary" onClick={handleSubmit}>
                        Submit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <ToastContainer />
                </div>
            )
      }

      <div>
        <hr />
        { enrollmentsLoading ? <LoadSpinner /> :
          <OraclelearnRosterTable/>
        }
      </div>
    </div>
  );
};

export default ManageOfferingActivityRoster;